export const navigation = [
  { url: "/main/home", text: "首頁" },
  { url: "/main/news", text: "海洋污染防治", children: [], type: 'news' },
  { url: "/main/station", text: "測站資料" },
  {
    text: "監測資料庫",
    // url: "/main/ecology-database"
    children: [
      { url: "/main/ocean-database", text: "監測數據分析" },
      { url: "/main/ecology-database", text: "生態數據分析" }
    ]
  },
  { url: "/main/standar-rule", text: "法規資料" },
  {
    text: "海洋事務",
    children: [
      { url: "/main/books", text: "海洋高雄期刊" },
      { url: "/main/e-books", text: "電子書" },
      { url: "/main/publish-books", text: "出版品" },
      { url: "/main/album", text: "活動列表" },
    ]
  }
]
