import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ServiceProxyModule } from './shared/service-proxies/service-proxy.module';
import { VisitorCountComponent } from './component/visitor-count/visitor-count.component';

import { SettingService } from './shared/setting.service';

@NgModule({
  imports: [
    CommonModule,
    ServiceProxyModule,
    RouterModule.forChild([
      /* {path: '', pathMatch: 'full', component: InsertYourComponentHere} */
    ])
  ],
  declarations: [VisitorCountComponent],
  exports: [
    VisitorCountComponent
  ],
  providers: [
    SettingService
  ]
})
export class OdeModule {}
