import {AppComponentBase} from './app-component-base';
import { Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConsts } from './AppConsts';
import { PagedResultDto, PagedRequestDto } from './paged-listing-component-base';
import * as _ from 'lodash';

//Alex:擴充 PagedListingComponentBase
export abstract class PagedListingComponentBaseCheckbox<EntityDto> extends AppComponentBase implements OnInit {

    route: ActivatedRoute;
    router: Router;
    public pageSize: number = AppConsts.defaultPageSize;
    public pageNumber: number = 1;
    public totalPages: number = 1;
    public totalItems: number;
    public isTableLoading = false;

    dataItems: any = [];
    allChecked = false;
    indeterminate = false;
    constructor(injector: Injector) {
        super(injector);
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
    }


    ngOnInit(): void {
        //this.refresh();
    }

    refresh(): void {
        if (this.pageNumber > 0)
            this.getDataPage(this.pageNumber);
        this.indeterminate = false;
        this.allChecked = false;
    }

    public showPaging(result: PagedResultDto, pageNumber: number): void {
        this.totalPages = ((result.totalCount - (result.totalCount % this.pageSize)) / this.pageSize) + 1;

        this.totalItems = result.totalCount;
        this.pageNumber = pageNumber;
    }

    public getDataPage(page: number): void {
        var req = new PagedRequestDto();
        req.maxResultCount = this.pageSize;
        req.skipCount = (page - 1) * this.pageSize;

        this.isTableLoading = true;
        this.list(req, page, () => {
            this.isTableLoading = false;
        });
    }
    protected abstract list(request: PagedRequestDto, pageNumber: number, finishedCallback: Function): void;
    protected abstract delete(entity: EntityDto): void;

    //nzTable Checkbox要用的  function
    currentPageDataChange($event: Array<any>): void {
        this.dataItems = $event;
        this.refreshStatus();
    }
    refreshStatus() {
        const allChecked = this.dataItems.filter(value => !value.disabled).every(value => value.checked === true);
        const allUnChecked = this.dataItems.filter(value => !value.disabled).every(value => !value.checked);

        this.allChecked = allChecked;
        this.indeterminate = (!allChecked) && (!allUnChecked);
    }

    checkAll(value: boolean): void {
        this.dataItems.forEach(data => {
            if (!data.disabled) {
                data.checked = value;
            }
        });
        this.refreshStatus();
    }

    //刪除後要確認 Table 有沒有資料 :沒有要跳頁
    checkDataset(datas) {
        var count = 0;
        _.forEach(datas, (item) => {
            if (_.some(this.dataItems, { id: item.id })) {
                count++;
            }
        });
        if (this.dataItems.length == count && this.pageNumber > 1) {
            this.pageNumber = this.pageNumber - 1;
            this.refresh();
        }
        
    }
    appendCheckboxData() {
        _.forEach(this.dataItems, (x) => {
            x.checked = false;
            x.disabled = false;
        });
    }
    public getSelectedData() {
        return this.dataItems.filter(value => !value.disabled && value.checked === true);
    }

    protected abstract deleteArray(): void;
    
}
