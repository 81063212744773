import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER, Injector, } from '@angular/core';
import { AppComponent } from './app.component';
import { NxModule } from '@nrwl/nx';
import { RouterModule } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule, PaginationModule } from 'ngx-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeHant from '@angular/common/locales/zh-Hant';
import localeHantExtra from '@angular/common/locales/extra/zh-Hant';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CmsModule } from '@fs/cms';
import { OdeModule } from '@fs/ode';
import { FormsModule } from '@angular/forms';
import { DynFormModule } from '@fs/dyn-form';
import { LazyLoadImageModule } from 'ng-lazyload-image';
registerLocaleData(localeHant, localeHantExtra);
import { AbpModule } from '@fs/abp';
import { StartupServiceFactory } from './StartupServiceFactory';

const APPINIT_PROVIDES = [
  {
    provide: APP_INITIALIZER,
    useFactory: StartupServiceFactory,
    deps: [Injector, PlatformLocation],
    multi: true
  }
];

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent],
  imports: [

    BrowserModule,
    BrowserAnimationsModule,
    AbpModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    CollapseModule.forRoot(),
    CmsModule,
    OdeModule,
    FormsModule,
    DynFormModule,
    LazyLoadImageModule,
    PaginationModule.forRoot()
  ],
  providers: [
    APPINIT_PROVIDES
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
