import { Injector, ElementRef } from '@angular/core';
import { AppConsts } from './AppConsts';
import { LocalizationService } from '@abp/localization/localization.service';
import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { FeatureCheckerService } from '@abp/features/feature-checker.service';
import { NotifyService } from '@abp/notify/notify.service';
import { SettingService } from '@abp/settings/setting.service';
import { MessageService } from '@abp/message/message.service';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { AppSessionService } from './session/app-session.service';

export abstract class AppComponentBase {

  localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

  localization: LocalizationService;
  permission: PermissionCheckerService;
  feature: FeatureCheckerService;
  notify: NotifyService;
  setting: SettingService;
  message: MessageService;
  multiTenancy: AbpMultiTenancyService;
  appSession: AppSessionService;
  elementRef: ElementRef;

  constructor(injector: Injector) {
    this.localization = injector.get(LocalizationService);
    this.permission = injector.get(PermissionCheckerService);
    this.feature = injector.get(FeatureCheckerService);
    this.notify = injector.get(NotifyService);
    this.setting = injector.get(SettingService);
    this.message = injector.get(MessageService);
    this.multiTenancy = injector.get(AbpMultiTenancyService);
    this.appSession = injector.get(AppSessionService);
    this.elementRef = injector.get(ElementRef);
  }

  /**
* keyOrParam: string 或 Array<string>	, key值或參數
* l("HomePage")
* > 回傳預設SourceName中的HomePage值
* l(["HomePage", "YC"])
* > 回傳YC中的HomePage值
*/
  l(keyOrParam: any, ...args: any[]): string {
    let localizedText: string = undefined;

    if (typeof (keyOrParam) === 'string') {
      localizedText = this.localization.localize(keyOrParam, this.localizationSourceName);

      if (!localizedText) localizedText = keyOrParam;
    }
    else if (typeof (keyOrParam) === 'object') {
      localizedText = this.localization.localize(keyOrParam[0], keyOrParam[1]);

      if (!localizedText) localizedText = keyOrParam[0];
    }
    else {
      console.warn('Unsupported parameter "' + keyOrParam + '" for localization!');
      return keyOrParam;
    }

    if (!args || !args.length) {
      return localizedText;
    }

    args.unshift(localizedText);
    return abp.utils.formatString.apply(this, args);
  }
  /**
   * 實驗性方法
   * key: 鍵值
   * la("HomePage")
   * > 依序從載入順序中讀取有HomePage鍵值的語言
   * > 越前面加載的語言包優先讀取
   * 若要對Object尋找 可能需要lodash?
   */
  la(key: string, ...args: any[]): string {
    let localizedText = key;
    for (var i of abp.localization.sources) {
      if (abp.localization.values[i.name].hasOwnProperty(key)) {
        localizedText = this.localization.localize(key, i.name)
        break;
      }
    }

    if (!args || !args.length) {
      return localizedText;
    }

    args.unshift(localizedText);
    return abp.utils.formatString.apply(this, args);
  }
  /**
   * sourceName: string	, 語言來源
   * key: string			, 鍵值
   * lt("YC", "HomePage")
   * > 回傳YC中的HomePage值
   */
  lt(sourceName: string, key: string, ...args: any[]) {
    let localizedText = this.localization.localize(key, sourceName);
    if (!localizedText) {
      localizedText = key;
    }

    if (!args || !args.length) {
      return localizedText;
    }

    args.unshift(localizedText);
    return abp.utils.formatString.apply(this, args);
  }

  isGranted(permissionName: string): boolean {
    return this.permission.isGranted(permissionName);
  }
}
