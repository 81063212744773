import { Injectable } from '@angular/core';
import { SettingServiceProxy, SettingDto } from './service-proxies/service-proxies';

@Injectable()
export class SettingService {

	settings = new SettingDto();
	
	constructor(private settingServProxy: SettingServiceProxy) {
		
		// 在剛進站時Service就會起來, 因此增加人數應只會執行一次
		
		// 讀取設定
		this.settingServProxy.load().subscribe((x) => {
			this.settings = x;
		});
  }

  addCount() {
    this.settingServProxy.addVisitorCount().subscribe((x) => {

    });
  }
}
