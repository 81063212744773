import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RootModule } from './root.module';

@NgModule({
  imports: [
    CommonModule,
    RootModule
  ]
})
export class AbpModule {}
