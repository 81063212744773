import { Component } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'fs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'doe-web';
}
