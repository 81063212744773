import { Component, OnInit,Input } from '@angular/core';
import {SettingServiceProxy} from '../../shared/service-proxies/service-proxies';

@Component({
  selector: 'fs-visitor-count',
  templateUrl: './visitor-count.component.html',
  styleUrls: ['./visitor-count.component.sass']
})
export class VisitorCountComponent implements OnInit {
  @Input() type: string;
  settingData : any;
  constructor(
    private settingServiceProxy : SettingServiceProxy
  ) {

  }

  ngOnInit() {
    console.log(this.type);
    this.settingServiceProxy.addVisitorCount().subscribe((x) => {});
    this.settingServiceProxy.load().subscribe((x) => {
      console.log(x);
      this.settingData = x;
    });
  }
}
