import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SimpleGuard } from '@delon/auth';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

const routes: Routes = [
  { path: '', redirectTo: '/main/home?newId=3', pathMatch: "full" },
  {
    path: 'main',
    loadChildren: './main/main.module#MainModule'
  },
  {
    path: 'demo',
    loadChildren: './demo/demo.module#DemoModule'
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
