import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { AppConsts } from '@fs/abp';
import {Router,NavigationEnd} from '@angular/router';

import { navigation } from '../../navigation/navigation';

import { NewsTypeCrudServiceProxy } from '@fs/cms';

import { SettingService } from '@fs/ode';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, AfterViewInit {
	adminUrl: string;
	links = navigation;
	
	constructor(private newsTypeServProxy: NewsTypeCrudServiceProxy,
        private router: Router,
				private settingServ: SettingService) {}
	
	ngOnInit() {
  this.router.events.subscribe((val) => {
      
        $('#main-nav').removeClass('in');
    });
		
		//後端網址
		this.adminUrl = AppConsts.appBaseUrl + '/admin';
		
		// 將後端有的新聞類型讀入選單
		this.newsTypeServProxy.getAll({}).subscribe((res)=>{
			let nav = navigation;
			nav.forEach((lnk)=>{
				if (lnk.type == 'news')
					lnk.children = res.items.sort((i,j)=>{
						return i.sequence - j.sequence
					}).map((newsType)=>{
						return {
							text: newsType.name,
							url: lnk.url + '/' + newsType.id
						}
					});
			})
			this.links = nav;
		});
	}

	ngAfterViewInit() {
		$.getScript('./assets/js/custom.js', function () { });
  }
  closeCollapse() {
    //var class = $.('#main-nav').attr('class');
    //console.log(class);
  }
	
	s(p: string) {
		return this.settingServ.settings[p];
	}

}
