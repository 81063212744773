import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookSubscriberCrudServiceProxy, BookDto_WithRelation, SettingService } from '@fs/ode';
import {version} from '@fs/package';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
	email: string = "";
  version = version;
	constructor(private router: Router,
		private bookSubscriberCrudServiceProxy: BookSubscriberCrudServiceProxy,
		private settingServ: SettingService) {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	}

	ngAfterViewInit() {
		$.getScript('./assets/js/custom.js', function () {
		});
	}

	ngOnInit() {
	
	}

	subscribe() {
		//this.bookSubscriberCrudServiceProxy.subscribe(this.email).subscribe((x) => {
		//})
	}
	
	s(p: string) {
		return this.settingServ.settings[p];
	}
}
