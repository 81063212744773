import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from '@abp/abpHttpInterceptor';
import * as ApiServiceProxies from './service-proxies';
import { AppConsts } from '@fs/abp';
import { API_BASE_URL } from './service-proxies';
@NgModule({
  providers: [
    ApiServiceProxies.StationCrudServiceProxy,
    ApiServiceProxies.AlbumCrudServiceProxy,
    ApiServiceProxies.BookCrudServiceProxy,
    ApiServiceProxies.BookSubscriberCrudServiceProxy,
    ApiServiceProxies.SettingServiceProxy,
	  ApiServiceProxies.MailingServiceProxy,
    ApiServiceProxies.StationRecordCrudServiceProxy,
    ApiServiceProxies.StationAppServiceProxy,
    ApiServiceProxies.RegulationCrudServiceProxy,
    ApiServiceProxies.EmailNotifyLoggerCrudServiceProxy,
    ApiServiceProxies.RecordTimeStampCrudServiceProxy,
    { provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl },
    { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
  ]
})
export class ServiceProxyModule { }
export function getRemoteServiceBaseUrl(): string {
  return AppConsts.remoteServiceBaseUrl;
}
