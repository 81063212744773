import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ServiceProxyModule } from './shared/service-proxies/service-proxy.module';

@NgModule({
  imports: [
    CommonModule,
    ServiceProxyModule,
    RouterModule.forChild([
      /* {path: '', pathMatch: 'full', component: InsertYourComponentHere} */
    ])
  ]
})
export class CmsModule {}
