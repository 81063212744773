import { NgModule, LOCALE_ID, APP_INITIALIZER, Injector } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { AppConsts, AppPreBootstrap, AppSessionService } from '@fs/abp';
export function StartupServiceFactory(injector: Injector,
  platformLocation: PlatformLocation): Function {

  //return () => startupService.load();
  return () => {

    //abp.ui.setBusy();
    return new Promise<boolean>((resolve, reject) => {
      AppConsts.appBaseHref = getBaseHref(platformLocation);
      const appBaseUrl = getDocumentOrigin() + AppConsts.appBaseHref;

      AppPreBootstrap.run(appBaseUrl, () => {
        abp.event.trigger('abp.dynamicScriptsInitialized');
        const appSessionService: AppSessionService = injector.get(AppSessionService);
        //abp start
        appSessionService.init().then(
          (result) => {
            //abp.ui.clearBusy();
            resolve(result);
            //if (shouldLoadLocale()) {
            //    const angularLocale = convertAbpLocaleToAngularLocale(abp.localization.currentLanguage.name);
            //    import(`@angular/common/locales/${angularLocale}.js`)
            //        .then(module => {
            //            registerLocaleData(module.default);
            //            resolve(result);
            //        }, reject);
            //} else {
            //    resolve(result);
            //}
          },
          (err) => {
            //abp.ui.clearBusy();
            reject(err);
          }
        ).then(() => {
        });
      });
    });
  };
}

export function getBaseHref(platformLocation: PlatformLocation): string {
  const baseUrl = platformLocation.getBaseHrefFromDOM();
  if (baseUrl) {
    return baseUrl;
  }

  return '/';
}

function getDocumentOrigin() {
  if (!document.location.origin) {
    const port = document.location.port ? ':' + document.location.port : '';
    return document.location.protocol + '//' + document.location.hostname + port;
  }

  return document.location.origin;
}
